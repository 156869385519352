import React from 'react';
import { useHistory } from "react-router-dom"
import { useContext } from 'react';
import BannerContext from '../data/BannerContext';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'
import { dataPrices } from '../_testdata/data'
import { useState } from 'react';
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'

import '../custom.css'
import WarningPopup from './warningPopup';

function BannerSize() {

    const [buttonPopup, setButtonPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const history = useHistory();
    const BannerCtx = useContext(BannerContext);


    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);


    const [customWidth, setcustomWidth] = useState(0);
    const [customLength, setcustomLength] = useState(0);

    function submitCreateBanner() {

        if (BDDetails.bannerSize == "") {
            setWarningMessage("Must select a size.");
            setButtonPopup(true);
            return;
        }

        history.push("/banner-edit");
    }

    function calcPrice(w, l) {

        var thePrice = 0.0;
        var dPrices = CustomerDetails.media.media;

loop1:
        for (var item in dPrices) {
            if (dPrices[item].name == BDDetails.mediaType) {

                var prices = dPrices[item].size;
                for (var i = 0; i < prices.length ; i++) {
                    if (parseInt(prices[i].width) >= parseInt(w) && parseInt(prices[i].length) >= parseInt(l)) {
                        thePrice = prices[i].price;
                        break loop1;
                    }
                }
            }
        }

        return thePrice;
    }

    function submitSize(w, l) {
        var stringSize = w.toString() + "x" + l.toString();

        if (BDDetails.horizontal) {
            setBDDetails({
                mediaType: BDDetails.mediaType,
                horizontal: BDDetails.horizontal,
                bannerSize: stringSize,
                bannerWidth: w,
                bannerHeight: l,
                bannerPrice: calcPrice(w, l),
                bannerQuantity: 2,
                bannerCode: "xxx-xxx"
            });
        }
        else {
            setBDDetails({
                mediaType: BDDetails.mediaType,
                horizontal: BDDetails.horizontal,
                bannerSize: stringSize,
                bannerWidth: l,
                bannerHeight: w,
                bannerPrice: calcPrice(w, l),
                bannerQuantity: 2,
                bannerCode: "xxx-xxx"
            });
        }

        setcustomWidth(w);
        setcustomLength(l);

        console.log(CustomerDetails);
    }


    function submitHandler_EnterCustomSize() {

        let w = parseInt(customWidth);
        let l = parseInt(customLength);

        if (parseInt(w) == 0 || parseInt(l) == 0)
            return;

        let maxSize = getMaxSize();

        if (parseInt(w) > parseInt(maxSize)) {
            if (parseInt(l) > parseInt(maxSize)) {
                setWarningMessage("Width can not exceed " + maxSize.toString() + " inches.");
                setButtonPopup(true);
                return;
            }
        }



        if (parseInt(w) < parseInt(l)) {
            w = parseInt(customWidth);
            l = parseInt(customLength);
        }
        else {
            w = parseInt(customLength);
            l = parseInt(customWidth);
        }

        if (parseInt(l) > parseInt(120)) {
            setWarningMessage("Length can not exceed 120 inches");
            setButtonPopup(true);
            return;
        }

        var stringSize = w.toString() + "x" + l.toString();

        if (BDDetails.horizontal) {
            setBDDetails({
                mediaType: BDDetails.mediaType,
                horizontal: BDDetails.horizontal,
                bannerSize: stringSize,
                bannerWidth: w,
                bannerHeight: l,
                bannerPrice: calcPrice(w, l),
                bannerQuantity: 2,
                bannerCode: "xxx-xxx"
            });
        }
        else {
            setBDDetails({
                mediaType: BDDetails.mediaType,
                horizontal: BDDetails.horizontal,
                bannerSize: stringSize,
                bannerWidth: l,
                bannerHeight: w,
                bannerPrice: calcPrice(w, l),
                bannerQuantity: 2,
                bannerCode: "xxx-xxx"
            });
        }
    }

    function getMaxSize() {
        var maxSize = 24;
        var dPrices = CustomerDetails.media.media;

        loop1:
        for (var item in dPrices) {
            if (dPrices[item].name == BDDetails.mediaType) {

                maxSize = dPrices[item].max;
                break;
            }
        }
        console.log(maxSize);
        return maxSize;
    }

    function isGreaterThan36() {

        var theResult = false;
        var dPrices = CustomerDetails.media.media;
        console.log(dPrices);
        console.log(dataPrices);

        loop1:
        for (var item in dPrices) {
            console.log(dPrices[item]);
            console.log(BDDetails.mediaType);
            if (dPrices[item].name == BDDetails.mediaType) {
                console.log("match.");

                if (parseInt(dPrices[item].max) >= parseInt(36)) {
                    theResult = true;
                }
                break;

            }
        }

  
        return theResult;
    }

    return (
        <div>
             <label className="heading-type" >Select Banner Size</label>
       <div class="parent-div-sizing">
           <div class="child-div-sizing-narrow">
                <button className="btn btn-size" onClick={() => submitSize(18, 24)}>24x18 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 36)}>24x36 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 48)}>24x48 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 60)}>24x60 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 72)}>24x72 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 84)}>24x84 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 96)}>24x96 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 108)}>24x108</button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(24, 120)}>24x120</button><br /><br />
            </div>
            <div class="child-div-sizing-narrow">
                <br /><br />
                <div className={isGreaterThan36() ? undefined : 'hidden'} >
                <button className="btn btn-size" onClick={() => submitSize(36, 36)}>36x36 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 48)}>36x48 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 60)}>36x60 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 72)}>36x72 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 84)}>36x84 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 96)}>36x96 </button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 108)}>36x108</button><br /><br />
                <button className="btn btn-size" onClick={() => submitSize(36, 120)}>36x120</button><br /><br />
                </div>
                </div>
            <div class="child-div-sizing">
                <br /><br /><br /><br /><br /><br /><br />
                <label className="label-type" >Input Custom Size</label><br /><br />
                <input value={customWidth} onChange={(e) => { setcustomWidth(e.target.value) }} className="size_input" type="text" size="20"></input><br/><br/>
                <input value={customLength} onChange={(e) => { setcustomLength(e.target.value) }} className= "size_input" type="text"></input><br/><br/>
                <button className="btn btn-size" onClick={submitHandler_EnterCustomSize}>Enter Size</button><br /><br />

            </div>
            <div class="child-div-sizing">
                <p className="label-type">
                    <br /><br /><br />
                    Summary:<br />
                    Media Type : {BDDetails.mediaType} <br />
                    Orientation : {BDDetails.horizontal ? "Landscape" : "Portrait"} <br />
                    Banner Size : {BDDetails.bannerSize} <br />
                    Banner Price : ${BDDetails.bannerPrice} <br />
                    

                </p>
                <br /><br /><br />
                <button className="btn btn-primary btn-rounded" onClick={submitCreateBanner} > Create Banner</button>

        </div>
            </div>
            <WarningPopup trigger={buttonPopup} setTrigger={setButtonPopup} message={warningMessage} >
            </WarningPopup>
        </div>
    );
}

export default BannerSize;