
export const bannerSportsTemplates = [
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball5.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball6.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball7.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/baseball8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/baseball8.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball5.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball6.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball7.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball8.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/Basketball9_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/Basketball9.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/cheerleading1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/cheerleading1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/cheerleading2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/cheerleading2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/cheerleading3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/cheerleading3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/CrossCountry1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/CrossCountry1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/CrossCountry2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/CrossCountry2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football5.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/football6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/football6.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer5.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/soccer6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/soccer6.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_5.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_6.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_7.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/sports_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/sports_8.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/tennis1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/tennis1.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/tennis2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/tennis2.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/tennis3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/tennis3.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/tennis4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/tennis4.jpg",
    },
    {
        tag: "Sports",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sports/tennis5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sports/tennis5.jpg",
    },
];

export const bannerSimpleTemplates = [
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple10_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple10_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple11_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple11_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple12_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple12_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple13_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple13_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple14_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple14_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple15_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple15_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple16_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple16_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple17_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple17_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple18_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple18_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple19_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple19_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple1_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple1_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple20_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple20_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple21_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple21_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple22_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple22_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple23_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple23_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple2_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple2_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple3_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple3_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple4_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple4_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple5_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple5_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple6_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple6_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple7_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple7_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple8_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple8_042020.jpg",
    },
    {
        tag: "Simple",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Simple/Simple9_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Simple/Simple9_042020.jpg",
    },
];

export const bannerSaleTemplates = [
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash1.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash2.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash3.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash4.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash5.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash6.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/CarWash7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/CarWash7.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_2.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_3.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_4.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_5.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_6.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_7.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale_8.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/For_Sale_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/For_Sale.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/GrandOpening2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/GrandOpening2.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/GrandOpening3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/GrandOpening3.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/GrandOpening4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/GrandOpening4.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/GrandOpening_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/GrandOpening.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home1.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home2.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home3.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home4.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home5.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home6.jpg",
    },
    {
        tag: "Sale",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Sale/home7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Sale/home7.jpg",
    },
];

export const bannerPatternsTemplates = [
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_10_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_10_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_11_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_11_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_12_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_12_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_13_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_13_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_14_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_14_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_15_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_15_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_16_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_16_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_17_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_17_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_18_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_18_042020.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_19_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_19_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_1.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_20_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_20_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_21_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_21_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_22_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_22_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_23_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_23_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_24_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_24_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_25_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_25_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_26_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_26_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_27_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_27_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_28_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_28_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_29_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_29_092022.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_2.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_3.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_4.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_5.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_6.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_7.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_8.jpg",
    },
    {
        tag: "Patterns",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Patterns/Pattern_9_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Patterns/Pattern_9_042020.jpg",
    },
];

export const bannerMilitaryTemplates = [
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_1.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_2.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_3.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_4.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_5.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_6.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_7.jpg",
    },
    {
        tag: "Military",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Military/military_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Military/military_8.jpg",
    },
];
export const bannerHolidayTemplates = [
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_10_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_10_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_11_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_11_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_12_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_12_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_13_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_13_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_14_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_14_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_15_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_15_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_16_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_16_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_17_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_17_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_18_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_18_042020.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_19_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_19_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_1.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_20_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_20_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_21_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_21_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_22_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_22_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_23_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_23_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_24_092022_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_24_092022.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_2.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_3.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_4.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_5.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_6.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_7.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_8.jpg",
    },
    {
        tag: "Holiday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Holiday/holiday_9_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Holiday/holiday_9_042020.jpg",
    },
];
export const bannerGrandOpeningTemplates = [
    {
        tag: "Grand Opening",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Grand_Opening/GrandOpening2_Preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Grand_Opening/GrandOpening2.jpg",
    },
    {
        tag: "Grand Opening",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Grand_Opening/GrandOpening3_Preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Grand_Opening/GrandOpening3.jpg",
    },
    {
        tag: "Grand Opening",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Grand_Opening/GrandOpening4_Preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Grand_Opening/GrandOpening4.jpg",
    },
    {
        tag: "Grand Opening",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Grand_Opening/GrandOpening_Preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Grand_Opening/GrandOpening.jpg",
    },
];
export const bannerGraduationTemplates = [
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_1.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_2.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_3.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_4.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_5.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_6.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_7.jpg",
    },
    {
        tag: "Graduation",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Graduation/graduation_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Graduation/graduation_8.jpg",
    },
];

export const bannerCheckTemplates = [
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check1a_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check1a_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check1_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check1_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check2a_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check2a_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check2_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check2_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check3a_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check3a_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check3_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check3_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check4a_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check4a_042020.jpg",
    },
    {
        tag: "Checks",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Checks/check4_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Checks/check4_042020.jpg",
    },

];

export const bannerCelebrationTemplates = [
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_1.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_2.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_3.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_4.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_5.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_6.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_7.jpg",
    },
    {
        tag: "Celebration",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Celebration/celebration_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Celebration/celebration_8.jpg",
    },

];

export const bannerTemplates = [
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_10_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_10.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_11_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_11.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_12_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_12.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_13_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_13.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_14_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_14.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_15_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_15.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_16_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_16.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_1.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_2.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_3.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_4.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_5.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_6.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_7.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_8.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_9_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_9.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday16_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday16.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday17_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday17_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday18_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday18_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday19_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday19_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday20_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday20_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday21_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday21_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday22_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday22_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday23_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday23_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday24_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday24_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday25_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday25_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday26_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday26_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday27_042020_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday27_042020.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_1_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_1.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_2_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_2.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_3_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_3.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_4_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_4.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_5_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_5.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_6_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_6.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_7_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_7.jpg",
    },
    {
        tag: "Birthday",
        preview: "https://fz-image-assets.s3.us-east-2.amazonaws.com/backgrounds/Birthday/Birthday_Banner_8_preview.jpg",
        highres: "https://fz-image-assets.s3.us-east-2.amazonaws.com/i/hr/Birthday/Birthday_Banner_8.jpg",
    },
];

export const dataPrices = [
    {
        name: "Outdoor Banner",
        max: 36,
        size: [
            {
                width: 16,
                length: 20,
                price: 20.21
            },
            {
                width: 18,
                length: 24,
                price: 22.74
            },
            {
                width: 20,
                length: 30,
                price: 37.9
            },
            {
                width: 22,
                length: 28,
                price: 37.9
            },
            {
                width: 24,
                length: 30,
                price: 41.86
            },
            {
                width: 24,
                length: 36,
                price: 45.47
            },
            {
                width: 24,
                length: 48,
                price: 60.63
            },
            {
                width: 24,
                length: 60,
                price: 75.79
            },
            {
                width: 24,
                length: 72,
                price: 90.95
            },
            {
                width: 24,
                length: 84,
                price: 106.11
            },
            {
                width: 24,
                length: 96,
                price: 121.26
            },
            {
                width: 36,
                length: 30,
                price: 56.84
            },
            {
                width: 36,
                length: 36,
                price: 68.21
            },
            {
                width: 36,
                length: 48,
                price: 90.95
            },
            {
                width: 36,
                length: 60,
                price: 113.96
            },
            {
                width: 36,
                length: 72,
                price: 136.42
            },
            {
                width: 36,
                length: 84,
                price: 159.16
            },
            {
                width: 36,
                length: 96,
                price: 181.9
            },
            {
                width: 36,
                length: 108,
                price: 204.63
            },
            {
                width: 36,
                length: 120,
                price: 227.37
            },
            {
                width: 42,
                length: 60,
                price: 151.58
            },
            {
                width: 42,
                length: 72,
                price: 181.9
            },
            {
                width: 42,
                length: 84,
                price: 212.21
            },
            {
                width: 42,
                length: 96,
                price: 242.53
            },
            {
                width: 42,
                length: 108,
                price: 272.84
            },
            {
                width: 42,
                length: 120,
                price: 303.16
            }
        ]
    },
    {
        name: "Indoor Banner",
        max: 24,
        size: [
            {
                width: 11,
                length: 14,
                price: 10.56
            },
            {
                width: 13,
                length: 19,
                price: 11.95
            },
            {
                width: 16,
                length: 20,
                price: 12.79
            },
            {
                width: 18,
                length: 24,
                price: 14.39
            },
            {
                width: 20,
                length: 30,
                price: 23.98
            },
            {
                width: 22,
                length: 28,
                price: 23.98
            },
            {
                width: 24,
                length: 30,
                price: 26.38
            },
            {
                width: 24,
                length: 36,
                price: 28.78
            },
            {
                width: 24,
                length: 48,
                price: 38.37
            },
            {
                width: 24,
                length: 60,
                price: 47.95
            },
            {
                width: 24,
                length: 72,
                price: 57.55
            },
            {
                width: 24,
                length: 84,
                price: 67.14
            },
            {
                width: 24,
                length: 96,
                price: 76.74
            },
            {
                width: 36,
                length: 30,
                price: 35.97
            },
            {
                width: 36,
                length: 36,
                price: 43.16
            },
            {
                width: 36,
                length: 48,
                price: 57.55
            },
            {
                width: 36,
                length: 60,
                price: 71.94
            },
            {
                width: 36,
                length: 72,
                price: 86.33
            },
            {
                width: 36,
                length: 84,
                price: 100.72
            },
            {
                width: 36,
                length: 96,
                price: 115.1
            },
            {
                width: 36,
                length: 108,
                price: 129.49
            },
            {
                width: 36,
                length: 120,
                price: 143.88
            },
            {
                width: 42,
                length: 60,
                price: 95.92
            },
            {
                width: 42,
                length: 72,
                price: 115.1
            },
            {
                width: 42,
                length: 84,
                price: 134.29
            },
            {
                width: 42,
                length: 96,
                price: 153.47
            },
            {
                width: 42,
                length: 108,
                price: 172.66
            },
            {
                width: 42,
                length: 120,
                price: 191.84
            }
        ]
    },

];

export const mediaData = [
    {
        media: "Indoor Banner",
        max: "24",
    },
    {
        media: "Outdoor Banner",
        max: "24",
    },
    {
        media: "Plain Bond",
        max: "36",
    },
    {
        media: "Foto-Lustre",
        max: "24",
    },
    {
        media: "Satin Canvas",
        max: "24",
    },
    {
        media: "Glossy Wrapping Paper",
        max: "24",
    },
];