import React from 'react'
import { bannerTemplates, bannerCelebrationTemplates, bannerCheckTemplates, bannerGraduationTemplates, bannerGrandOpeningTemplates, bannerHolidayTemplates, bannerMilitaryTemplates, bannerPatternsTemplates, bannerSaleTemplates, bannerSimpleTemplates, bannerSportsTemplates } from '../_testdata/data'
import './popup.css'
import { useState } from 'react';



function PleaseWait(props) {

    function onCloseButton() {
        props.setTrigger(false);
    }



    return (props.trigger) ? (
        <div className="warning-popup ">
            <div className="wait-popup-inner warning-center">
                <h1 className="wait-blue">Please Wait</h1><br />
                <img className="img-pleasewait" src="https://fz-image-assets.s3.us-east-2.amazonaws.com/graphics/Uploading.gif"/>
                {props.children}
            </div>
        </div>
    ) : "";
}

export default PleaseWait