import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'
import { createContext, useState, useEffect, useContext } from "react";
import { dataPrices } from '../_testdata/data'
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'

import axios from 'axios';

function Home(props) {
    const history = useHistory();

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    useEffect(() => {
        console.log("*****************************");
        let temp = props.location.pathname;
        temp = temp.substring(temp.length - 8);
        console.log(temp);
        console.log(props.location);
        console.log("*****************************");
        getCustomer(temp);

    },[]);

    function submitHandler() {

        setBDDetails({
            mediaType: "",
            horizontal: true,
            bannerSize: "",
            bannerWidth: 0,
            bannerHeight: 0,
            bannerPrice: 0,
            bannerCode: "xxx-xxx"
        });

        history.push("/create-banner");
    }

    function BuildJSON(customerID) {
        var object = {};
        object["customerID"] =  customerID;

        var json = JSON.stringify(object);
        console.log(json);
        return json;
    }

    function getCustomer(customerID) {

        var json = BuildJSON(customerID);

        const api = 'https://noh8vjq312.execute-api.us-east-2.amazonaws.com/CustomerAdmin'
        const data = {
            body: json
        };
        const header = { 'Content-Type': 'application/json' };
        axios
            .post(api, data, { header })
            .then((response) => {
                console.log(response.data.body);
                var temp = JSON.parse(response.data.body).msg.customer;
                console.log("temp:")
                console.log(temp);
                console.log(temp.media);
                setCustomerDetails(temp);
            })
            .catch((error) => {
                console.log(error);
            });



    }

    return (
        <div>
            <br /><br />
            <div class="div-center">
                <p className="label-type">Welcome to the online print solution for {CustomerDetails.name}.</p><br/>
                <button className="btn btn-primary btn-rounded" onClick={submitHandler}>Create a Banner</button>
            </div>
            <br /><br />
      </div>
    );
}

export default Home;
