import React from 'react';
import { useHistory } from "react-router-dom"
import { useContext } from 'react';
import BannerContext from '../data/BannerContext';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'
import { useState } from 'react';
import axios from 'axios';
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'
import PhoneInput from 'react-phone-input-2'
import { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext } from '../data/printPreview';
import PleaseWait from './pleaseWaitPopup'; 
import WarningPopup from './warningPopup';
import '../custom.css'

function BannerDone() {

    const printPreview = React.useContext(printPreviewCtx);
    //state = { phone1: '' };
    //function handleOnChange(value, data, event, formattedValue) {
    //    console.log(data.dialCode);
    //    setPhone({ phone: value });
    //}

    const [pleaseWaitPopup, setPleaseWaitPopup] = useState(false);
    const [waitMessage, setWaitMessage] = useState('');

    const [buttonPopup, setButtonPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');


    const [fname, setfName] = useState('');
    const [lname, setlName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tacCheckBox, settacCheckbox] = useState(false);

    const history = useHistory();
    const BannerCtx = useContext(BannerContext);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);

    function validatePhoneNumber(input_str) {
        //var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return re.test(input_str);
    }

    function justDoIt() {

        if (fname == "") {
            setWarningMessage("Must fill out first name field.");
            setButtonPopup(true);
            return;
        }

        if (lname == "") {
            setWarningMessage("Must fill out last name field.");
            setButtonPopup(true);
            return;
        }

        if (email == "") {
            setWarningMessage("Must fill out E-Mail address.");
            setButtonPopup(true);
            return;
        }

        if (email.includes("@") == false) {
            setWarningMessage("Please provide a properly formated E-Mail address.");
            setButtonPopup(true);
            return;
        }

        if (phone == "") {
            setWarningMessage("Must fill out phone number.");
            setButtonPopup(true);
            return;
        }

        if (validatePhoneNumber(phone) == false) {
            setWarningMessage("Must provide a properly formatted phone number. (xxx)xxx-xxxx or xxx.xxx.xxxx");
            setButtonPopup(true);
            return;
        }

        if (tacCheckBox == false) {
            setWarningMessage("Must review the Terms and Agreement.");
            setButtonPopup(true);
           return;
        }



        componentDidMount();

    }

    function checkBoxChange() {
        console.log("checkbox");
        console.log(tacCheckBox);
        if (tacCheckBox)
            settacCheckbox(false);
        else
            settacCheckbox(true);
    }

    function BuildJSON() {
        var object = {};
        object["fname"] = fname;
        object["lname"] = lname;
        object["email"] = email;
        object["phone"] = phone;
        object["code"] = BDDetails.bannerCode;
        object["size"] = BDDetails.bannerSize;
        object["mediaType"] = BDDetails.mediaType;
        object["price"] = BDDetails.bannerPrice;
        object["customerID"] = CustomerDetails.customerID;

        var json = JSON.stringify(object);
        console.log(json);
        return json;
    }

    function componentDidMount() {

        setPleaseWaitPopup(true);

        var json = BuildJSON ();

        const api = 'https://d3evhyhtd8.execute-api.us-east-2.amazonaws.com/addwebjob?nname=rrob';
        const data = {
            body: json
        };
        const header = { 'Content-Type': 'application/json' };
        axios
            .post(api, data, { header })
            .then((response) => {
                console.log(response);
                setPleaseWaitPopup(false);
                history.push("/banner-congrats");
            })
            .catch((error) => {
                console.log(error);
            });



    } 

    return (
        <div>
        <div>
            <div class="label-type">First Name:<br/></div>
            <input className="input_text" value={fname} onChange={(e) => { setfName(e.target.value) }} type="text" /><br /><br />
            <div class="label-type">Last Name: <br/></div>
            <input className="input_text" value={lname} onChange={(e) => { setlName(e.target.value) }} type="text" /><br /><br />
            <div class="label-type">E-Mail Address: <br/></div>
            <input className="input_text" value={email} onChange={(e) => { setEmail(e.target.value); console.log(e.target.value); }} name="email" type="text" /><br /><br />
            <div class="label-type">Phone Number: <br/></div>
            <input value={phone} onChange={(e) => { setPhone(e.target.value) }} name="phone" type="text" /><br/><br/>
            {/*<PhoneInput*/}
            {/*    id="robPhone"*/}
            {/*    country={'us'}*/}
            {/*/>*/}
            <label className="label-type"><input className="myCheckbox" checked={tacCheckBox} onChange={checkBoxChange} type="checkbox"></input> I agree with the <a target="_blank" href="https://fz-image-assets.s3.us-east-2.amazonaws.com/tandc.html">Terms and Agreements.</a> </label><br /><br />
            <button className="btn btn-primary btn-rounded" onClick={justDoIt}>Submit Your Order and Contact Info</button>
            </div>
            <PleaseWait trigger={pleaseWaitPopup} setTrigger={setPleaseWaitPopup} message={waitMessage} >
            </PleaseWait>
            <WarningPopup trigger={buttonPopup} setTrigger={setButtonPopup} message={warningMessage} >
            </WarningPopup>
        </div>
    );
}

export default BannerDone;