import React from 'react'

import { useState, useContext } from 'react';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'
import { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext } from '../data/printPreview';

import './popup.css'

function PrintPreviewPopup(props) {

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const printPreview = React.useContext(printPreviewCtx);
    const setPrintPreview = useContext(printPreviewDispatchContext);

    const [quantity, setQuantity] = useState(1);

    function onCloseButton() {
        props.setTrigger(false);
    }

    function onAccept() {
        if (parseInt(quantity) < parseInt(1)) {
            alert("Quantity must be at least '1'");
            return;
        }

        if (parseInt(quantity) > parseInt(99)) {
            alert("Quantity must be less than '100'");
            return;
        }
        console.log("Print Prevew:")
        console.log(quantity);

        BDDetails.bannerQuantity = quantity;
        console.log(BDDetails.bannerQuantity);

        setBDDetails({
            mediaType: BDDetails.mediaType,
            horizontal: BDDetails.horizontal,
            bannerSize: BDDetails.bannerSize,
            bannerWidth: BDDetails.bannerWidth,
            bannerHeight: BDDetails.bannerHeight,
            bannerPrice: BDDetails.bannerPrice,
            bannerQuantity: BDDetails.bannerQuantity,
            bannerCode: BDDetails.bannerCode,
        });

        console.log(BDDetails.bannerQuantity);


        console.log(BDDetails);

        props.AcceptPrintPreview();
        props.setTrigger(false);
    }

    return (props.trigger) ? (
        <div className="popup-pp">
            <div className="popup-inner-pp">
                <div className="warning-center">
                    <h1>Print Preview</h1>

                    <img className={BDDetails.horizontal? 'img-horiz' : 'img-vert' } border="1" id="theimage" src={printPreview}></img><br /><br />

                        Quantity: <input className="input-size-pp" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} type="text" /><br /><br />
                    <button className="btn btn-pp-accept" onClick={onCloseButton}>Cancel - Return to Edit</button>
                    <button className="btn btn-pp-accept" onClick={onAccept}>Accept Preview</button><br />

                    {props.children}
                </div>
            </div>
        </div>
    ) : "";
}

export default PrintPreviewPopup