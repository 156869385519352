import React from 'react'
import { bannerTemplates, bannerCelebrationTemplates, bannerCheckTemplates, bannerGraduationTemplates, bannerGrandOpeningTemplates, bannerHolidayTemplates, bannerMilitaryTemplates, bannerPatternsTemplates, bannerSaleTemplates, bannerSimpleTemplates, bannerSportsTemplates } from '../_testdata/data'
import './popup.css'
import { useState } from 'react';



function WarningPopup(props) {

    function onCloseButton() {
        props.setTrigger(false);
    }



    return (props.trigger) ? (
        <div className="warning-popup ">
            <div className="warning-popup-inner warning-center">
                <h1 className="warning-red">Warning</h1><br />
                <p className="warning-text">{props.message} </p><br />
                <button className="btn btn-size-bannertemplates" onClick={onCloseButton}>Ok</button><br />
                {props.children}
            </div>
        </div>
    ) : "";
}

export default WarningPopup