import React from 'react';
import { useHistory } from "react-router-dom"
import { useContext } from 'react';
import BannerContext from '../data/BannerContext';
import { mediaData } from '../_testdata/data'
import { useState } from 'react';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'

import WarningPopup from './warningPopup';

import '../custom.css'

function BannerMedia() {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const history = useHistory();
    const BannerCtx = useContext(BannerContext);
    const [displayPortrait, setDisplayPortrait] = useState(false);
    const [displayLandscape, setDisplayLandscape] = useState(true);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);

    function submitHandler(event) {

        if (BDDetails.mediaType == "") {
            setWarningMessage("Must select Media.");
            setButtonPopup(true);
            return;
        }

        event.preventDefault();
        history.push("/banner-size");
    }

    function change(e) {
        BDDetails.mediaType = e.target.value;
        BannerCtx.mediaType = e.target.value;
    }

    function changeOrientation(e) {
        if (e.target.value == "horizontal") {
            BannerCtx.horizontal = true;
            BDDetails.horizontal = true;
        }
        else {
            BannerCtx.horizontal = false;
            BDDetails.horizontal = false;
       }
    }

    function click_Portrait(e) {
        BannerCtx.horizontal = false;
        BDDetails.horizontal = false;
        setDisplayPortrait(true);
        setDisplayLandscape(false);
    }

    function click_Landscape (e) {
        BannerCtx.horizontal = true;
        BDDetails.horizontal = true;
        setDisplayPortrait(false);
        setDisplayLandscape(true);
    }



    return (
        <div>

            <div class="parent-div-media">
                <div class="child-div-media">
                    <label className="heading-type" for="mediaSelection">Select Media</label><br /><br/>
                    <select name="mediaSelection" id="mediaSelection" size="12" onChange={change} >
                        {CustomerDetails.media.media.map(allMedia => {
                                return <option value={allMedia.name}>{allMedia.name}</option>;
                            })}
                    </select>
                </div>
                <div class="child-div-media">
                        <div align="center" style={{ padding: '10px' }} >
                            <label className="heading-type">Select Orientation</label><br /><br />
                           <button className="btn-primary" onClick={click_Landscape}>Landscape</button>
                            <button className="btn-primary" onClick={click_Portrait} >Portrait</button>
                        </div>
                        <div align="center" style={{ padding: '10px' }} >
                            <img name="img1" width="200px" className={displayLandscape ? undefined : 'hidden'} src="https://fz-file.s3.amazonaws.com/web/pics/birthday_l.jpg" />
                            <img name="img2" height="200px" className={displayPortrait ? undefined : 'hidden'} src="https://fz-file.s3.amazonaws.com/web/pics/birthday_p.jpg" />
                        </div>
                </div>
                <div class="child-div-media" align="center">
                            <br /><br /><br />
                            <button className="btn btn-primary btn-rounded" onClick={submitHandler}> Create Banner</button>
                </div>
            </div>
            <WarningPopup trigger={buttonPopup} setTrigger={setButtonPopup} message={warningMessage} >
            </WarningPopup>
        </div>
    );
}

export default BannerMedia;