import React, { createContext, useState, useEffect, useContext } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const CustomerContext = createContext(undefined);
const CustomerDispatchContext = createContext(undefined);



// A "provider" is used to encapsulate only the
// components that needs the state in this context
function CustomerProvider({ children }) {


    const [CustomerDetails, setCustomerDetails] = useState(JSON.parse(localStorage.getItem('CustomerDetails')) ||
        {
        name: "Loading...",
        address1: "",
        address2: "",
        email: "",
        phone: "",
        media: "",
        customerID: "",
    });

    useEffect(() => {
        localStorage.setItem('CustomerDetails', JSON.stringify(CustomerDetails));
    }, [CustomerDetails]);

    return (
        <CustomerContext.Provider value={CustomerDetails}>
            <CustomerDispatchContext.Provider value={setCustomerDetails}>
                {children}
            </CustomerDispatchContext.Provider>
        </CustomerContext.Provider>
    );
}

export { CustomerProvider, CustomerContext, CustomerDispatchContext };
