import { createContext, useState } from 'react';

const BannerContext = createContext({
    mediaType: "Foto-Lustre",
    horizontal: true,
    bannerSize: "24x60",
    bannerWidth: 24,
    bannerHeight: 60,
    bannerCode: "xxx-xxx"
});

//export function BannerContextProvider(props) {
//    const [userFavorites, setUserFavorites] = useState([]);

//    //function updateContext(contex) {
//    //    return context;
//    //}

//    //function addFavoriteHandler(favoriteMeetup) {
//    //    setUserFavorites((prevUserFavorites) => {
//    //        return prevUserFavorites.concat(favoriteMeetup);
//    //    });
//    //}

//    //function removeFavoriteHandler(meetupId) {
//    //    setUserFavorites(prevUserFavorites => {
//    //        return prevUserFavorites.filter(meetup => meetup.id !== meetupId);
//    //    });
//    //}

//    //function itemIsFavoriteHandler(meetupId) {
//    //    return userFavorites.some(meetup => meetup.id === meetupId);
//    //}

//    const [context = {
//        mediaType: "Foto-Lustre",
//        horizontal: true,
//        bannerSize: "24x60"
//    }, setContext] = useState([]);

//    return (
//        <BannerContext.Provider value={context}>
//            {props.children}
//        </BannerContext.Provider>
//    );
//}

export default BannerContext;
