import React, { createContext, useState, useEffect } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const printPreviewCtx = createContext(undefined);
const printPreviewDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function PrintPreviewProvider({ children }) {
    const [printPreview, setPrintPreview] = useState(JSON.parse(localStorage.getItem('PrintPreview')) ||
    {});

    useEffect(() => {
        localStorage.setItem('PrintPreview', JSON.stringify(printPreview));
    }, [printPreview]);

    return (
        <printPreviewCtx.Provider value={printPreview}>
            <printPreviewDispatchContext.Provider value={setPrintPreview}>
                {children}
            </printPreviewDispatchContext.Provider>
        </printPreviewCtx.Provider>
    );
}

export { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext };
