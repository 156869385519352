import React from 'react';
import { useHistory } from "react-router-dom"
import { useContext } from 'react';
import BannerContext from '../data/BannerContext';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'
import { useState } from 'react';
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'
import { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext } from '../data/printPreview';

import axios from 'axios';

import '../custom.css'

function BannerCongrats() {

    const printPreview = React.useContext(printPreviewCtx);

    const history = useHistory();
    const BannerCtx = useContext(BannerContext);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);

 
    return (
        <div>
            <label className="heading-red"> Please print or save this for your records.</label>
            <br/><br/>
            <p className="heading-type1">Congratulations</p>
            <p className="label-type1">Please contact us to make payment arrangements and discuss details to get your job into production.<br /><br />
            </p>
            <p className="label-type1">{CustomerDetails.name}<br />{CustomerDetails.address1}<br />{CustomerDetails.address2}<br />{CustomerDetails.email}<br />{CustomerDetails.phone}</p>
            <br/><br/>
            <p className="label-type1"> Job Code: {BDDetails.bannerCode}</p>

            <img width={800} id="theimage" src={printPreview}></img><br /><br />
        </div>
    );
}

export default BannerCongrats;