import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useHistory } from "react-router-dom"
import BannerContext from '../data/BannerContext';
import { fabric } from 'fabric';
import { useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import Popup from './popup';
import PleaseWait from './pleaseWaitPopup';
import PrintPreviewPopup from './printPreviewPopup';
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData';
import { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext } from '../data/printPreview';

 
import '../custom.css'

function EditBanner(props) {
    const history = useHistory();
    const BannerCtx = useContext(BannerContext);

    const [lineOfText, setLineOfText] = useState('');

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    const printPreview = React.useContext(printPreviewCtx);
    const setPrintPreview = useContext(printPreviewDispatchContext);

    const [pleaseWaitPopup, setPleaseWaitPopup] = useState(false);
    const [waitMessage, setWaitMessage] = useState('');
    const [imageWarning, setImageWarning] = useState('');
    const [imageBackgroundWarning, setImageBackgroundWarning] = useState('');

    const bWidth = BDDetails.bannerWidth * 100;
    const bHeight = BDDetails.bannerHeight * 100;



    const [canvas, setCanvas] = useState('');
    const [theOverlay, setTheOverlay] = useState('');
    const [imgURL, setImgURL] = useState('');
    const [displayBackground, setDisplayBackground] = useState(true);
    const [displayImage, setDisplayImage] = useState(false);
    const [displayText, setDisplayText] = useState(false);
    const [displayItem, setDisplayItem] = useState(null);

    const [displayTextBox, setDisplayTextBox] = useState(false);

    const [buttonPopup, setButtonPopup] = useState(false);
    const [buttonPrintPreviewPopup, setButtonPrintPreviewPopup] = useState(false);

    var uploadURL = "";
    var global_count = 1000; //not needed

    useEffect(() => {
        setCanvas(initCanvas());
        //setTheOverlay(initOverlay());

        console.log("uuuuuuuuuuuuuuuuu");
        console.log(theOverlay);
        console.log(canvas);
    }, []);


    function initOverlay() {

        let c = new fabric.Canvas('canvas_overlay', {
            height: bWidth,
            width: bHeight,

        })
        let ratio = 0.20;
        c.setDimensions({ width: bHeight * ratio, height: bWidth * ratio });
        c.setZoom(ratio);

        return c;
    }

    function initCanvas() {

        let c = new fabric.Canvas('canvas', {
            height: bWidth,
            width: bHeight,
            backgroundColor: 'white',
        })
        let ratio = 0.20;
        c.setDimensions({ width: bHeight * ratio, height: bWidth * ratio });
        c.setZoom(ratio);

        c.on('mouse:down', function (options) {
            console.log("mouse down...");
            console.log(options.target);
            console.log(options);

            setDisplayItem(options.target);
            setDisplayTextBox(false);
            setImageWarning("");

            if (options.target == null) {
                clickBackground();
            } else if (options.target.type.toString() == "image") {
                clickImage();
                checkImageQuality(options.target);
            } else if (options.target.type.toString() == "i-text") {
                console.log("i-text");
                console.log(displayItem);
                console.log(options.target.text);
                setLineOfText(options.target.text);

                setDisplayBackground(false);
                setDisplayImage(false); 
                setDisplayText(true);

                setDisplayTextBox(true);
            } else {
                clickBackground();
            }

        });

        c.on('text:changed', function (e) {
            setLineOfText(e.target.text);
        });

        c.on('object:scaling', (options) => {
            //console.log(
            //    "Object was scaled, current scaleX:",
            //    options.target.scaleX,
            //    " and scaleY: ",
            //    options.target.scaleX);

            if (options.target.type.toString() == "image") {
                checkImageQuality(options.target);
            }
        });

       // c.on('object:modified', handleModified);

        return c;
    }

    function checkImageQuality(img) {
        if (img.scaleX > 2.2 || img.scaleY > 2.2) {
            setImageWarning("Enlarging your original image to this size is not recommended.");

            document.getElementById('imageMessage').className = 'div_red';
        }
        else if (img.scaleX > 1.4 || img.scaleY > 1.4) {
            setImageWarning("Your image may not appear crisp at close distances using this size.");
            document.getElementById('imageMessage').className = 'div_yellow';
        }
        else {
            setImageWarning("This size will faithfully reproduce the full detail of your original image.");
            document.getElementById('imageMessage').className = 'div_green';
        }
    }
    //function handleModified(evt) {
    //    console.log(evt.target);
    //    console.log(theOverlay);
    //    addToOverlay(200, 200);
    //}

    function onLoadBackgroundImage (event) {
        setImgURL(event.target.value);
        const inputElem = document.getElementById('selectImage');
        const file = inputElem.files[0];
        const reader = new FileReader();

        reader.addEventListener("load", function () {

            fabric.Image.fromURL(reader.result, function (img) {

                var sX = (canvas.width / img.width) / canvas.getZoom();
                var sY = (canvas.height / img.height) / canvas.getZoom();

                var sXratio = ( img.width / canvas.width );
                var sYratio = ( img.height / canvas.height );


                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: sX,
                    scaleY: sY
                });

                if (sXratio > 3.0 && sYratio > 3.0) {
                    setImageBackgroundWarning("Background Image Quality: This size will faithfully reproduce the full detail of your original image.");
                    document.getElementById('imageBackgroundMessage').className = 'div_green';
                }

                else if (sXratio > 2.0 && sYratio > 2.0) {
                    setImageBackgroundWarning("Background Image Quality: Your image may not appear crisp at close distances using this size.");
                    document.getElementById('imageBackgroundMessage').className = 'div_yellow';
                }
                else{
                    setImageBackgroundWarning("Background Image Quality: Enlarging your original image to this size is not recommended.");
                    document.getElementById('imageBackgroundMessage').className = 'div_red';
                }

                canvas.renderAll();

            }.bind(this), {
                crossOrigin: 'anonymous'
            });


        }, false);

        if (inputElem.files.length > 0)
            reader.readAsDataURL(file);
    }

    function myOnChange(event) {
        setImgURL(event.target.value);
        console.log('!' + imgURL + '!');

        const inputElem = document.getElementById('myUploadImg')
        const file = inputElem.files[0];

        const reader = new FileReader(); 

        reader.addEventListener("load", function () {
            // convert image file to base64 string
            //preview.src = reader.result;

            //var myScale = ;

            new fabric.Image.fromURL(reader.result, img => {
                console.log((canvas.width * 0.5) / img.width);
                img.scale((canvas.width * 0.5) / img.width);
                canvas.add(img);
                canvas.renderAll();
                setImgURL('');

                console.log(img.width);
                console.log(img.height);
                checkImageQuality(img);
            });

            document.getElementById("myUploadImg").value = "";

           // addToOverlay(100,100);


        }, false);

        console.log(file);

        reader.readAsDataURL(file);
    }

    function zoom(r) {
        let ratio = r;
        canvas.setDimensions({ width: bHeight * ratio, height: bWidth * ratio });
        canvas.setZoom(ratio)
    }

    function addText() {



        var ctx = canvas.getContext("2d");
        var px = ctx.getImageData(100, 100, 1, 1).data;

        var ccolor = 'red';
        if ((px[0] + px[1] + px[2]) > 382) {
            ccolor = 'black';
        }
        else {
            ccolor = 'white';
        }

        var text = canvas.add(new fabric.IText('Tap and Type', {
            fontFamily: 'arial black',
            fontSize: 100,
            left: 100,
            top: 100,
            fill: ccolor,
        }));

        canvas.discardActiveObject();  //deselect active object so new text is on top.
        canvas.renderAll(); 

        setDisplayTextBox(false);

    }

    function addToOverlay(posx, posy) {

        return;

        console.log("**************************add to overlay");
        console.log(posx);
        console.log(posy);

        var o = document.getElementById('canvas_overlay').fabric;

        console.log(o);
        console.log(theOverlay);

        var r = new fabric.Rect({
            width: 500,
            height: 500,
            left: posx,
            top: posy,
            fill: 'yellow',
            stroke: 'black',
            strokeWidth: 10
        });

        var r = o.add(r);

        //var ctx = theOverlay.getContext("2d");
        //var data = ctx.getImageData(0, 0, 200, 200);

        var dataURL = theOverlay.toDataURL("image/png");

        canvas.setOverlayImage(dataURL, canvas.renderAll.bind(canvas));
        canvas.renderAll();

    }

    function PrintIt() {
        console.log('anotherDownload')
        canvas.setDimensions({ width: bHeight, height: bWidth });
        canvas.setZoom(1)

        let dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: 1,
        })
        saveAs(dataURL, "pretty image.png")
    }

    //---------------- Upload File Stuff -----------------------
    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }
    function UploadFile() {

        console.log('anotherDownload')
        canvas.setDimensions({ width: bHeight, height: bWidth });
        canvas.setZoom(1)

        let dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: 1,
        })

        let blobData = dataURItoBlob(dataURL);

        //const result =
        fetch(uploadURL, {
            method: 'PUT',
            body: blobData,
            headers: { 'Content-Type': 'image/jpg' }
        })
            .then(function (result) {
                console.log(result);
                if (result.statusText == "OK") {
                    console.log('Image Uploaded');
                }
                else {
                    alert("Error uploading files, please try again.");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert("Error uploading files, please try again.");
            });
    }

    function UploadTextFile() {

        console.log('anotherDownload-text')

        var mystring = "<?xml version=\"1.0\" encoding=\"utf-8\"?><Product><store><company>Robs Print Shop</company><firstname>Rob</firstname><lastname>Miller</lastname><address>525 Sunset</address><city>Henderson</city><state>NV</state><zip>89011</zip><email>robm@fotozoomer.com</email><phone>702-485-4421</phone></store>";
        mystring += "<customer><firstname>Rob</firstname><lastname>Miller</lastname><address></address><city></city><state></state><zip></zip><email></email><phone></phone></customer>";
        mystring += "<width>" + BDDetails.bannerHeight * 100 + "</width>";
        mystring += "<height>" + BDDetails.bannerWidth * 100 + "</height>";
        mystring += "<media>" + BDDetails.mediaType + "</media>";
        mystring += "<printsize>" + BDDetails.bannerSize + "</printsize>";
        mystring += "<price>" + BDDetails.bannerPrice + "</price>";
        mystring += "<quantity>" + BDDetails.bannerQuantity + "</quantity><Frames></Frames><background><image_name>replace.jpg</image_name><red>255</red><blue>255</blue><green>255</green></background><TextLines></TextLines></Product > ";

         
        var myblob = new Blob([mystring], {
            type: 'text/plain'
        });


        //const result =
        fetch(uploadURL, {
            method: 'PUT',
            body: myblob,
            headers: { 'Content-Type': 'image/jpg' }
        })
            .then(function (result) {
                console.log(result);
                if (result.statusText == "OK") {

                    global_count--;
                    console.log("done");
                    setPleaseWaitPopup(false);
                    history.push("/banner-done");

                }
                else {
                    alert("Error uploading files, please try again.");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert("Error uploading files, please try again.");
            });
    }

    function getRndCharCode() {
        return Math.floor(Math.random() * 26) + 65;
    }

    function GenerateCode() {
        var p1 = String.fromCharCode(getRndCharCode(), getRndCharCode(), getRndCharCode(),getRndCharCode());
        var p2 = String.fromCharCode(getRndCharCode(), getRndCharCode(), getRndCharCode(),getRndCharCode());
        var newCode = 'W' + p1 + '-' + p2;
        return newCode;
    }

    function Confirm() {
        //This is a test to save an image and display in window.
        console.log("Confirm");

        let dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: 1,
        })

       // let blobData = dataURItoBlob(dataURL);
        setPrintPreview(dataURL);

        //document.getElementById("theimage").src = dataURL;
        setButtonPrintPreviewPopup(true);
        //canvas.renderAll();
    }

    function acceptAndUpload() {
        // Save it!
        setPleaseWaitPopup(true);

        var code = GenerateCode();
        BDDetails.bannerCode = code;

        setBDDetails({
            mediaType: BDDetails.mediaType,
            horizontal: BDDetails.horizontal,
            bannerSize: BDDetails.bannerSize,
            bannerWidth: BDDetails.bannerWidth,
            bannerHeight: BDDetails.bannerHeight,
            bannerPrice: BDDetails.bannerPrice,
            bannerQuantity: BDDetails.bannerQuantity,
            bannerCode: BDDetails.bannerCode
        });

        console.log("Generated Code:")
        console.log(code);
        console.log(BDDetails);

        callAwsLambdaFunction1();
        callAwsLambdaFunction2();

    }

    function doUploading(e) {

        Confirm();
        return;



    }

    function callAwsLambdaFunction1() {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var obj = JSON.parse(this.responseText);
                /*document.getElementById("myDiv").innerHTML = obj.msg;*/
                uploadURL = obj.msg;

                UploadFile();
            }
        }

        //xhttp.onerror = function () {
        //	alert("error.");
        //      }



        var filename = BDDetails.bannerCode + ".png";

        console.log(BDDetails.bannerCode);
        console.log(filename);

       // var url = "https://nr35bg7ld9.execute-api.us-east-2.amazonaws.com/default/fz_upload_job?file=";

        var url = "https://nr35bg7ld9.execute-api.us-east-2.amazonaws.com/default/fz_upload_job?file=";
        var usl = url.concat(filename);
        usl = usl.concat("&code=");
        usl = usl.concat(BDDetails.bannerCode);
        xhttp.open("GET", usl, true);
        xhttp.send();
    }

    function callAwsLambdaFunction2() {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var obj = JSON.parse(this.responseText);
                /*document.getElementById("myDiv").innerHTML = obj.msg;*/
                uploadURL = obj.msg;

                UploadTextFile();
            }
        }

        //xhttp.onerror = function () {
        //	alert("error.");
        //      }

        var code = BDDetails.bannerCode;
        var filename = code + ".xml";

        console.log(code);
        console.log(filename);

        var url = "https://nr35bg7ld9.execute-api.us-east-2.amazonaws.com/default/fz_upload_job?file=";
        var usl = url.concat(filename);
        usl = usl.concat("&code=");
        usl = usl.concat(code);
        xhttp.open("GET", usl, true);
        xhttp.send();
    }

    function getColor() {
        var color = document.getElementById("colorDialogID").value;
        canvas.backgroundColor = color;
        console.log(color);
        canvas.renderAll();
        //document.getElementById("textToColorID").style.color = color;
    }

    function clickBackground() {
        console.log("background click");
        setDisplayBackground(true);
        setDisplayImage(false);
        setDisplayText(false);
    }

    function clickImage() {
        setDisplayBackground(false);
        setDisplayImage(true);
        setDisplayText(false);
    }

    function clickText() {

        setDisplayBackground(false);
        setDisplayImage(false);
        setDisplayText(true);

        addText();

        setDisplayTextBox(false);

        console.log(displayTextBox);
    }


    //$(document.body).on('change', '#font-control', function () {
    //    //mytext.fontFamily = fontControl.val();
    //    //canvas.renderAll();

    //});

    function fontChange(e) {
        console.log('font change');
        var target = e.target;
        var message = target.value;
        //var fontControl = $('#font-control');
        console.log(message);
        displayItem.fontFamily = message;
        canvas.renderAll();

    }

    function changeArial() {
        displayItem.fontFamily = "Arial";
        canvas.renderAll();
    }
    function changeArialBlack() {
        displayItem.fontFamily = "Arial Black";
        canvas.renderAll();
    }
    function changeVerdana() {
        displayItem.fontFamily = "Verdana";
        canvas.renderAll();
    }
    function changePalace() {
        displayItem.fontFamily = "Palace Script MT";
        canvas.renderAll();
    }

    function changeLobster() {
        displayItem.fontFamily = "Lobster";
        canvas.renderAll();
    }

    function changeSaira() {
        displayItem.fontFamily = "Saira Stencil One";
        canvas.renderAll();
    }
    function changeAlfa() {
        displayItem.fontFamily = "Alfa Slab One";
        canvas.renderAll();
    }

    function changeChelsea() {
        displayItem.fontFamily = "Chelsea Market";
        canvas.renderAll();
    }
    function changeTimes() {
        displayItem.fontFamily = "Times New Roman";
        canvas.renderAll();
    }

    function changeRed() {
        console.log("red");
        displayItem.fill = "red";
        displayItem.dirty = true;
        canvas.renderAll();
    }
    function changeGreen() {
        console.log("green");
        displayItem.fill = "green";
        displayItem.dirty = true;
        canvas.renderAll();
    }
    function changeBlue() {
        console.log("blue");
        displayItem.fill = "blue";
        displayItem.dirty = true;
        canvas.renderAll();
    }
    function changeBlack() {
        console.log("black");
        displayItem.fill = "black";
        displayItem.dirty = true;
        canvas.renderAll();
    }
    function changeWhite() {
        console.log("white");
        displayItem.fill = "white";
        displayItem.dirty = true;
        canvas.renderAll();
    }
    function changeGrey() {
        console.log("grey1");
        displayItem.fill = "grey";
        displayItem.dirty = true;
        canvas.renderAll();
    }

    function changeOutlineColor(color) {
        console.log("stroke" + color);
        displayItem.stroke = color;
        displayItem.strokeWidth = 4;
        displayItem.dirty = true;
        canvas.renderAll();
    }

    function changeOutlineColorImage(color) {
        console.log(displayItem.width);
        var stroke = displayItem.width / 75;
        console.log(stroke);

        displayItem.stroke = color;
        displayItem.strokeWidth = stroke;
        displayItem.dirty = true;
        canvas.renderAll();
    }

    function clearOutline() {
        displayItem.stroke = null;
        displayItem.strokeWidth = 0;
        displayItem.dirty = true;
        canvas.renderAll();
    }

    function changeDropShadow(color) {
        displayItem.set({ shadow: 'rgba(0,0,0, 1.0) 7px 7px 7px' });
        displayItem.shadow.color = color;
        canvas.renderAll();
    }

    function changeDropShadowImage(color) {
        console.log(displayItem.width);
        var stroke = displayItem.width / 10;
        console.log(color);

        displayItem.set({ shadow: 'rgba(0,0,0, 1.0) 75px 75px 75px' });
        displayItem.shadow.color = color;
        //displayItem.shadowOffsetX = stroke;
        //displayItem.shadowOffsetY = stroke;
        canvas.renderAll();
    }

    function clearDropShadow() {
        displayItem.set({ shadow: 'rgba(0,0,0, 1.0) 0px 0px 0px' });
        displayItem.shadow.color = null;
        canvas.renderAll();
    }

    function removeText() {
        canvas.remove(displayItem);
        canvas.renderAll();

        setDisplayTextBox(false);
    }

    function onTemplates() {
        setButtonPopup(true);


        //new fabric.Image.fromURL(reader.result, img => {
        //    img.scale(0.10);
        //    canvas.add(img);
        //    canvas.renderAll();
        //    setImgURL('');
        //});
    }

    function onBackgroundImage(bckImage) {
        var data = bckImage;

        console.log(data);

        fabric.Image.fromURL(data, function (img) {

                // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: (canvas.width / img.width) / canvas.getZoom(),
                scaleY: (canvas.height / img.height) / canvas.getZoom()
                });

                document.getElementById('imageBackgroundMessage').className = 'div_black';
                setImageBackgroundWarning("");

        }.bind(this), {
            crossOrigin: 'anonymous'
        });

        canvas.renderAll();
    }

    function onClearBackground() {
        if (canvas) {
            document.getElementById('imageBackgroundMessage').className = 'div_black';
            setImageBackgroundWarning("");

            canvas.setBackgroundImage(null);
            canvas.setBackgroundColor('white');
            canvas.renderAll();
        }
    }

    function onPlusBackground() {
        let z = canvas.getZoom();
        let h = canvas.height / z;
        let w = canvas.width / z;
        console.log(z);
        console.log(w);
        console.log(h);

        let ratio = z / 1.25;
        canvas.setDimensions({ width: w * ratio, height: h * ratio });
        canvas.setZoom(ratio);
        canvas.renderAll();
    }

    function onMinusBackground() {
        let z = canvas.getZoom();
        let h = canvas.height / z;
        let w = canvas.width / z;

        let ratio = z * 1.25;
        canvas.setDimensions({ width: w * ratio, height: h * ratio });
        canvas.setZoom(ratio);
        canvas.renderAll();
    }

    function uploadSelectImage() {
        document.getElementById("selectImage").click()
    }

    function uploadmyImage() {
        document.getElementById("myUploadImg").click()
    }

    function removeImage() {
        setImageWarning("");
        document.getElementById('imageMessage').className = 'div_black';

        canvas.remove(displayItem);
        canvas.renderAll();
    }

    function centerHorizontal() {
        console.log(displayItem.getBoundingRect().width);
        console.log(displayItem.scaleX);
        console.log(canvas.width / canvas.getZoom());

        displayItem.left = (((canvas.width / canvas.getZoom()) / 2) - (displayItem.getBoundingRect().width * displayItem.scaleX / 2));
        canvas.renderAll();
    }

    function centerVertical() {
      //  displayItem.top = (((canvas.height / canvas.getZoom()) / 2) - (displayItem.height * displayItem.scaleY / 2));
        canvas.renderAll();
    }

    function centerHorizontalImage() {
        displayItem.left = (((canvas.width / canvas.getZoom()) / 2) - (displayItem.width * displayItem.scaleX / 2));
        canvas.renderAll();
    }

    function centerVerticalImage() {
        displayItem.top = (((canvas.height / canvas.getZoom()) / 2) - (displayItem.height * displayItem.scaleY / 2));
        canvas.renderAll();
    }


    //------------------------------------------------------------

    return (
        <div>
            <div class="row">
                <div class="column1">
                        <table cellSpacing='0' cellPadding='0' align='left'>
                            <tr>
                                <td className='tdEditBanner' align='left'><img onClick={clickBackground} width='65px' src='https://fz-file.s3.amazonaws.com/web/webtool/img/background.jpg'/></td>
                            </tr>
                            <tr>
                                <td className='tdEditBanner' align='left'><img onClick={clickImage} width='65px' src='https://fz-file.s3.amazonaws.com/web/webtool/img/picture.jpg' /></td>
                            </tr>
                            <tr>
                                <td className='tdEditBanner' align='left'><img onClick={clickText} width='65px' src='https://fz-file.s3.amazonaws.com/web/webtool/img/text.jpg' /></td>
                            </tr>
                            <tr>
                                <td className='tdEditBanner' align='left'><img onClick={doUploading} width='65px' src='https://fz-file.s3.amazonaws.com/web/webtool/img/print.jpg' /></td>
                            </tr>
                        </table>
                </div>
                <div class="column2">
                        <div width='220px'  id='background_tool' className={displayBackground ? undefined : 'hidden'}>
                            <p className="label-type">Background Controls</p><br/>
                            <label className='label-type'>Background Color</label><br/>
                            <input className='toolsButton' type="color" id="colorDialogID" onChange={getColor} /><br/><br/>
                            <button className='toolsButton' onClick={onTemplates}>Background Template</button><br /><br />
                            <button className='toolsButton' onClick={uploadSelectImage}>Import Background</button><br /><br />
                            <button className='toolsButton' onClick={onClearBackground}>Clear Background </button><br /><br />
                            <input id='selectImage' accept="image/*" hidden type="file" onChange={onLoadBackgroundImage} />
                        <br />
                        <br />
                        <div id="imageBackgroundMessage" className="div_black"><label className="label-type-small">{imageBackgroundWarning}</label></div>

                        </div>
                        <div width='220px' id='image_tool' className={displayImage ? undefined : 'hidden'}>
                            <p className="label-type">Image Controls</p><br/>

                                <button className='toolsButton' onClick={uploadmyImage}>Add Photo</button><br /><br />
                            <button className='toolsButton' onClick={removeImage}>Remove Photo</button><br /><br />

                            <input id="myUploadImg" type="file" hidden accept="image/*" onChange={myOnChange} /> 

                            <label className="label-type-small">Outline Color</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => clearOutline()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/none.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('black')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/black.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('red')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/red.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('blue')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/blue.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('white')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/white.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('green')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/green.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColorImage('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey2.jpg' /></td></tr>
                            </table>
                            <br />
                            <label className="label-type-small">Drop Shadow Color</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => clearDropShadow()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/none.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('black')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/black.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('red')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/red.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('blue')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/blue.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('white')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/white.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('green')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/green.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadowImage('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey2.jpg' /></td></tr>
                            </table>
                            <br />
                            <label className="label-type">Center</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img width='40px' onClick={() => centerHorizontalImage()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/HorizontalCenter.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => centerVerticalImage()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/VerticalCenter.jpg' /></td></tr>
                            </table>
                            <br />
                            <div id="imageMessage" className="div_black"><label className="label-type-small">{imageWarning}</label></div>
                            <br/>
                            </div>
                        <div width='220px' id='text_tool' className={displayText ? undefined : 'hidden' }>
                            <p className="label-type">Text Controls</p>
                            <label className="label-type-small">Color</label>
                            <table>
                            <tr><td className='toolsEditBanner'></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeBlack} src='https://fz-file.s3.amazonaws.com/web/webtool/img/black.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeRed} src='https://fz-file.s3.amazonaws.com/web/webtool/img/red.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeBlue} src='https://fz-file.s3.amazonaws.com/web/webtool/img/blue.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeWhite} src='https://fz-file.s3.amazonaws.com/web/webtool/img/white.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeGreen} src='https://fz-file.s3.amazonaws.com/web/webtool/img/green.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeGrey} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeGrey} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey2.jpg' /></td></tr>
                            </table>
                            <br/>
                            <label className="label-type-small">Font</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeArial} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeArialBlack} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a2.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeTimes} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a3.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeChelsea} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a4.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeAlfa} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a5.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeSaira} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a6.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changeLobster} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a7.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={changePalace} src='https://fz-file.s3.amazonaws.com/web/webtool/img/a8.jpg' /></td></tr>
                            </table>
                            <br />
                            <label className="label-type-small">Outline Color</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => clearOutline()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/none.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('black')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/black.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('red')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/red.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('blue')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/blue.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('white')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/white.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('green')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/green.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeOutlineColor('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey2.jpg' /></td></tr>
                            </table>
                            <br />
                            <label className="label-type-small">Drop Shadow Color</label>
                            <table>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => clearDropShadow()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/none.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('black')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/black.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('red')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/red.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('blue')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/blue.jpg' /></td></tr>
                            <tr><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('white')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/white.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('green')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/green.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey1.jpg' /></td><td className='toolsEditBanner'><img className="toolsIcon" onClick={() => changeDropShadow('grey')} src='https://fz-file.s3.amazonaws.com/web/webtool/img/grey2.jpg' /></td></tr>
                            </table>
                        <br />
                            <button className='toolsButton' onClick={removeText}>Remove Text</button><br /><br />

                        </div>
                        {/*<button onClick={PrintIt}>Local Print</button>*/}
                        {/*<button onClick={doUploading}>Upload Result </button>*/}
                </div>
                <div class="column3">
                    <div>
                        <input className={displayTextBox ? undefined : 'hidden'} value={lineOfText} onChange={(e) => { console.log(e.target.value); setLineOfText(e.target.value); displayItem.set('text', e.target.value); canvas.renderAll(); }} type="text" /> <br /><br />
                        <img width='25px' onClick={() => onPlusBackground()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/minusSelected.png' /> <label className="label-type-small">&nbsp;&nbsp;zoom&nbsp;&nbsp;</label> <img width='25px' onClick={() => onMinusBackground()} src='https://fz-file.s3.amazonaws.com/web/webtool/img/plusSelected.png' />
  
                        <div className="theCanvas">
                            <div className="wrapper">
                                <canvas className="canvas-position"  id="canvas" />
                            {/*    <canvas className="canvas-position1"  id="canvas_overlay" />*/}
                            </div>
                       </div>
                    </div>
                </div>
             </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} setBackgroundImage={onBackgroundImage }>
            </Popup>
            <PrintPreviewPopup trigger={buttonPrintPreviewPopup} setTrigger={setButtonPrintPreviewPopup} AcceptPrintPreview={acceptAndUpload}>
            </PrintPreviewPopup>
            <PleaseWait trigger={pleaseWaitPopup} setTrigger={setPleaseWaitPopup} message={waitMessage} >
            </PleaseWait>

        </div>

    );
}

export default EditBanner;