import React, { createContext, useState, useEffect } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const BDContext = createContext(undefined);
const BDDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function BDProvider({ children }) {
    const [BDDetails, setBDDetails] = useState(JSON.parse(localStorage.getItem('__BannerDetails')) ||
        {
            mediaType: "",
            horizontal: true,
            bannerSize: "",
            bannerWidth: 0,
            bannerHeight: 0,
            bannerPrice: 0.00, 
            bannerCode: "xxx-xxx",
            bannerQuantity: 1,
       }); 

    //const [BDDetails, setBDDetails] = useState({
    //    mediaType: "Foto-Lustre",
    //    horizontal: true,
    //    bannerSize: "24x60",
    //    bannerWidth: 24,
    //    bannerHeight: 60,
    //    bannerPrice: 0.00,
    //    bannerCode: "xxx-xxx"
    //}); 

    useEffect(() => {
        localStorage.setItem('__BannerDetails', JSON.stringify(BDDetails));
    }, [BDDetails]);

    return (
        <BDContext.Provider value={BDDetails}>
            <BDDispatchContext.Provider value={setBDDetails}>
                {children}
            </BDDispatchContext.Provider>
        </BDContext.Provider>
    );
}

export { BDProvider, BDContext, BDDispatchContext };
