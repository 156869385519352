import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Home1 from './components/Home1';
import BannerMedia from './components/BannerMedia'
import BannerSize from './components/BannerSize'
import Banner from './components/Banner'
import BannerDone from './components/BannerDone'
import BannerCongrats from './components/BannerCongrats'
import Popup from './components/popup'
import { useState } from 'react';

import EditBanner from './components/EditBanner'

import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { BDProvider, BDContext, BDDispatchContext } from './data/BannerData'
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from './data/CustomerData'
import { PrintPreviewProvider, printPreviewCtx, printPreviewDispatchContext } from './data/printPreview'
 
import './custom.css'

function App() {

    function componentDidMount() {
        console.log("***********************************Did Mount");
        //getCustomer();
    }

    return (
        <PrintPreviewProvider>
        <CustomerProvider>
        <BDProvider>
        <Layout>
        <div>
           
                        <Route path='/s/:id' component={Home} />
                        <Route path='/fz' component={Home1}/>
            <Route path='/create-banner'>
                <BannerMedia />
            </Route>
            <Route path='/banner-size'>
                <BannerSize />
            </Route>
            <Route path='/banner'>
            <Banner />
            </Route>
            <Route path='/banner-edit'>
            <EditBanner />
            </Route>
            <Route path='/banner-done'>
                <BannerDone />
            </Route>
            <Route path='/banner-congrats'>
                <BannerCongrats />
            </Route>
            <Route path='/fetch-data'>
                <FetchData />
            </Route>
            </div>

            </Layout>
        
            </BDProvider>
            </CustomerProvider>
        </PrintPreviewProvider>
    );
}

export default App;

//export default class App extends Component {
//  static displayName = App.name;

//  render () {
//    return (
//      <Layout>
//        <Route exact path='/' component={Home} />
//        <Route path='/counter' component={Counter} />
//        <Route path='/fetch-data' component={FetchData} />
//      </Layout>
//    );
//  }
//}
