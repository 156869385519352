import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useHistory } from "react-router-dom"
import BannerContext from '../data/BannerContext';
import EditBanner from './EditBanner'
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'

import '../custom.css'

function Banner(props) {
    const history = useHistory();
    const BannerCtx = useContext(BannerContext);
    const [showText, setShowText] = useState(true);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    function submitHandler() {
        //event.preventDefault();
        //history.push("/banner")
        setShowText(!showText);
    }

    return (
        <div>
            {showText && <div id="summary">
                <p className="label-type">
                    Summary:<br />
                    Media Type : {BannerCtx.mediaType} <br />
                    Horizontal : {BannerCtx.horizontal ? "true" : "false"} <br />
                    BannerSize : {BannerCtx.bannerSize} <br />

                </p>
                <button className="btn btn-primary" onClick={submitHandler}>Create Banner</button>
            </div>}
            {!showText && <div><EditBanner /></div>}
        </div>
    );
}

export default Banner;