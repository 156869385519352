import React from 'react'
import { bannerTemplates, bannerCelebrationTemplates, bannerCheckTemplates, bannerGraduationTemplates, bannerGrandOpeningTemplates, bannerHolidayTemplates, bannerMilitaryTemplates, bannerPatternsTemplates, bannerSaleTemplates, bannerSimpleTemplates, bannerSportsTemplates } from '../_testdata/data'
import './popup.css'
import { useState } from 'react';



function Popup(props) {
    const [templates, setTemplates] = useState(bannerTemplates);

    function onCloseButton() {
        props.setTrigger(false);
    }

    function onBirthday() {
        setTemplates(bannerTemplates);
    }

    function onCelebration() {
        setTemplates(bannerCelebrationTemplates);
    }

    function onChecks() {
        setTemplates(bannerCheckTemplates);
    }

    function onGrad() {
        setTemplates(bannerGraduationTemplates);
    }

    function onGrandOpening() {
        setTemplates(bannerGrandOpeningTemplates);
    }

    function onHoliday() {
        setTemplates(bannerHolidayTemplates);
    }

    function onMilitary() {
        setTemplates(bannerMilitaryTemplates);
    }

    function onPatterns() {
        setTemplates(bannerPatternsTemplates);
    }

    function onSale() {
        setTemplates(bannerSaleTemplates);
    }

    function onSimple() {
        setTemplates(bannerSimpleTemplates);
    }

    function onSports() {
        setTemplates(bannerSportsTemplates);
    }
      

    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <h1>Select a Background Template</h1>
                <div class="parent-div-bannertemplates">
                    <div className="content child-div-bannertemplates-buttons">
                    
                        <button className="btn btn-size-bannertemplates" onClick={onBirthday}>Birthday</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onCelebration}>Celebration</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onChecks}>Checks</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onGrad}>Graduation</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onGrandOpening}>Grand Opening</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onHoliday}>Holiday</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onMilitary}>Military</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onPatterns}>Pattern</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onSale}>Sale</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onSimple}>Simple</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onSports}>Sports</button><br />
                        <button className="btn btn-size-bannertemplates" onClick={onCloseButton}>Cancel</button><br />
                    </div>
                 
                    <div className="content child-div-bannertemplates-images">
                        <div class="parent-div-templates" id="scrollit">
                            {templates.map((item) => {
                        return (
                            <div className="content child-div-templates">
                                <img src={item.preview} alt="" width={250} border='1' onClick={() => { props.setTrigger(false); props.setBackgroundImage(item.highres) }} />
                                <br />
                                <br />

                            </div>
                        )
                    })}
                    </div>
                    </div>

                </div>
                {props.children}
            </div>
        </div>
    ) : "";
}

export default Popup