import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { CustomerProvider, CustomerContext, CustomerDispatchContext } from '../data/CustomerData'
import { createContext, useState, useEffect, useContext } from "react";
import { dataPrices } from '../_testdata/data'
import { BDProvider, BDContext, BDDispatchContext } from '../data/BannerData'

import axios from 'axios';

function Home1(props) {
    const history = useHistory();

    const CustomerDetails = React.useContext(CustomerContext);
    const setCustomerDetails = useContext(CustomerDispatchContext);

    const BDDetails = React.useContext(BDContext);
    const setBDDetails = useContext(BDDispatchContext);

    useEffect(() => {
        console.log("-----------------");
    }, []);

    function submitHandler() {

        setBDDetails({
            mediaType: "",
            horizontal: true,
            bannerSize: "",
            bannerWidth: 0,
            bannerHeight: 0,
            bannerPrice: 0,
            bannerCode: "xxx-xxx"
        });

        history.push("/create-banner");
    }

 
    return (
        <div>
            <br /><br />
            <div class="div-center">
                <p className="label-type">Welcome to the online print solution for {CustomerDetails.name}.</p><br />
                <button className="btn btn-primary btn-rounded" onClick={submitHandler}>Create a Banner</button>
            </div>
            <br /><br />
        </div>
    );
}

export default Home1;
